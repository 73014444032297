import { Button } from '@mui/material'
import React from 'react'
interface PaymentCardProps {
    paymentMethod: 'creditCard' | 'bankTransfer';
    onSelect: (method: 'creditCard' | 'bankTransfer') => void;
    selected: boolean;
    disabled?: boolean;
}
const PaymentCard:React.FC<PaymentCardProps>  = ({ paymentMethod, onSelect, selected, disabled = false }) => {
    return (
        <Button
            disabled={disabled}
            variant={selected ? 'contained' : 'outlined'}
            onClick={() => onSelect(paymentMethod)}
            sx={{ marginRight: 2, marginBottom: 2 }}
        >
            {paymentMethod === 'creditCard' ? 'บัตรเครดิต/บัตรเดบิต' : 'โอนเงินผ่านธนาคาร'}
        </Button>
    )
}

export default PaymentCard