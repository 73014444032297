// src/components/Loading.tsx
import React from 'react';
import { CircularProgress, Typography, Box } from '@mui/material';
import { styled } from '@mui/system';

interface LoadingProps {
    loading: boolean;
}

const StyledBackdrop = styled('div')({
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 1000, // Set your desired zIndex value here
    backgroundColor: 'rgba(255, 255, 255, 0.7)', // Adjust the opacity here
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
});

const Loading: React.FC<LoadingProps> = ({ loading }) => {
    return (
        <>
            {loading && (
                <StyledBackdrop>
                    <Box>
                        <CircularProgress size={60} thickness={4} color="primary" />
                        <Typography variant="h6" color="textSecondary" marginTop={2}>
                            รอสักครู่...
                        </Typography>
                    </Box>
                </StyledBackdrop>
            )}
        </>
    );
};

export default Loading;
