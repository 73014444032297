import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { GetTransaction } from '../helpers/ApiService';
import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from '@mui/material';
import { displayNumber } from '../helpers/Tools';
import Loading from '../components/Loading';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useNavigate } from 'react-router-dom';

const Admin = () => {
    const navigate = useNavigate();
    const userInfo = useSelector((state: any) => state.userInfo);
    const [dataList, setDataList] = useState<any[]>([])
    const [pagination, setPagination] = useState<any>({})
    const [isLoading, setIsLoading] = useState<boolean>(false)
    useEffect(() => {
        fetchTransaction(1, null)
    }, [])
    const fetchTransaction = (page: number, item_per_page: number | null) => {
        setIsLoading(true)
        const payload = {
            page: page,
            item_per_page: item_per_page || pagination.item_per_page
        }
        GetTransaction(payload).then((response: any) => {
            console.log('response', response);
            setDataList(response.list)
            setPagination(response.pagination)
            setIsLoading(false)
        }).catch((error: any) => {
            console.log('error', error);
            setDataList([])
            setPagination({})
            setIsLoading(false)
        })
    }
    const handleChangePage = (event: any, page: number) => {
        console.log('handleChangePage', event);
        console.log('page', page);
        fetchTransaction(page + 1, null)
    }
    const handleChangeRowsPerPage = (event: any) => {
        console.log('handleChangeRowsPerPage', event);
        fetchTransaction(1, event.target.value)
    }
    return (
        <div style={{ position: 'relative' }}>
            <Loading loading={isLoading} />
            <Typography padding={2}>
                <Button onClick={()=>navigate('/')}>{`< กลับหน้าหลัก`}</Button> Transactions
            </Typography>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer >
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    id
                                </TableCell>
                                <TableCell>
                                    created_by
                                </TableCell>
                                <TableCell>
                                    account
                                </TableCell>
                                <TableCell>
                                    amount
                                </TableCell>
                                <TableCell>
                                    currency
                                </TableCell>
                                <TableCell>
                                    name
                                </TableCell>
                                <TableCell>
                                    received_point
                                </TableCell>
                                <TableCell>
                                    upload
                                </TableCell>
                                <TableCell>
                                    transaction_time
                                </TableCell>
                                <TableCell>
                                    approved
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                dataList.map((item: any, index: number) => {
                                    const account = item.account || {}
                                    const upload = item.file || {}
                                    return <TableRow hover role="checkbox">
                                        <TableCell>
                                            {item.id}
                                        </TableCell>
                                        <TableCell>
                                            {item.create?.username || item.created_by}
                                        </TableCell>
                                        <TableCell>
                                            <Typography>
                                                <div>{account.title}</div>
                                                <div>{account.name}</div>
                                                <div>{account.no}</div>
                                            </Typography>
                                            {/* {item.account_id} */}
                                        </TableCell>
                                        <TableCell>
                                            {displayNumber(item.amount)}
                                        </TableCell>
                                        <TableCell>
                                            {item.currency}
                                        </TableCell>
                                        <TableCell>
                                            {item.name}
                                        </TableCell>
                                        <TableCell>
                                            {displayNumber(item.received_point)}
                                        </TableCell>
                                        <TableCell>
                                            <Typography>
                                            <span style={{ cursor: 'pointer', color: 'green', alignItems: 'center', display: 'flex' }} onClick={() => window.open(upload.path, '_blank')}>{upload.name}<OpenInNewIcon /> </span>
                                                {/* <div>{account.name}</div>
                                                <div>{account.no}</div> */}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            {item.transaction_time}
                                        </TableCell>
                                        <TableCell>
                                            {item.is_approved ? 'approved' : 'not approve'}
                                        </TableCell>
                                    </TableRow>
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={pagination.total_item || 0}
                    rowsPerPage={pagination.item_per_page || 25}
                    page={pagination.page - 1 || 0}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </div>
    )
}

export default Admin