import { Button, Card, CardContent, CardMedia, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import Loading from './Loading';
import { AddTransaction, GetAccountList, UploadFile } from '../helpers/ApiService';
import { alertService } from '../helpers/AlertService';
import CustomAlert from './CustomAlert';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { displayNumber } from '../helpers/Tools';
interface ModalPaymentProps {
    open: boolean;
    onSuccess: () => void;
    onError: () => void;
    onCancel: () => void;
    details: any;
}
const ModalPayment: React.FC<ModalPaymentProps> = ({ open, onSuccess, onError, onCancel, details }) => {
    const [selectedDateTime, setSelectedDateTime] = useState<string>('');
    const [isLoading, setIsLoading] = useState(false)
    const [showAlert, setShowAlert] = useState<boolean>(false)
    const [alertOption, setAlertOption] = useState({ message: 'success', type: 'success' })
    const [file, setFile] = useState<any | null>(null)
    const [accounts, setAccounts] = useState<any[]>([])
    const [selectedAccount, setSelectedAccount] = useState<any | null>()
    useEffect(() => {
        if (open) {
            const dateNow = new Date();
            const formattedDate = dateNow.toISOString().slice(0, 16).replace('T', ' ');
            setSelectedDateTime(formattedDate)
            getAccount(details?.selectedPayment == 'bankTransfer' ? 1 : 2);
        }
    }, [open])
    const getAccount = (master_id: number) => {
        setIsLoading(true)
        GetAccountList(master_id).then((response: any) => {
            setAccounts(response)
            if (response?.length > 0) {
                setSelectedAccount(response[0] || null)
            }
            setIsLoading(false)
        }).catch((error: any) => {
            setAccounts([])
            setIsLoading(false)
        })
    }
    const changeSelected = () => {
        const current_index = accounts.findIndex(item => item.id == selectedAccount?.id)
        if ((current_index + 1) <= (accounts.length - 1)) {
            setSelectedAccount(accounts[current_index + 1])
        } else {
            setSelectedAccount(accounts[0])
        }
    }

    const handleChange = (e: any) => {
        console.log('e.target?.value', e.target?.value);

        setSelectedDateTime(e.target?.value);
    };
    const handleFileChange = (e: any) => {
        const file = e.target?.files[0]
        console.log('file', file);
        setIsLoading(true)
        UploadFile(file).then((response: any) => {
            console.log('response', response);
            setFile(response)
            notiSuccess(`อัพโหลดรูปภาพสำเร็จ`)
            setIsLoading(false)
        }).catch((error: any) => {
            console.log('error', error)
            setFile(null)
            notiError(`อัพโหลดรูปภาพไม่สำเร็จ`)
            setIsLoading(false)
        })
    }
    const notiSuccess = (message?: string) => {
        setAlertOption({ message: message || 'success', type: 'success' })
        setShowAlert(true)
    }
    const notiError = (message?: string) => {
        setAlertOption({ message: message || 'error', type: 'error' })
        setShowAlert(true)
    }
    const confirmTransaction = () => {
        setIsLoading(true)
        const payload: any = {
            account_id: selectedAccount?.id,
            amount: details?.amount,
            currency: details?.selectedCurrency,
            received_point: details?.point,
            name: details?.gameName,
            category_id: 1,
            upload_id: file?.id,
            transaction_time: new Date(selectedDateTime)
        }
        console.log('payload', payload);
        AddTransaction(payload).then((response: any) => {
            onSuccess()
            notiSuccess('ทำรายการสำเร็จ')
            setIsLoading(false)
        }).catch((error: any) => {
            notiError('เกิดข้อผิดพลาด')
            setIsLoading(false)
        })

    }
    const handleClose = () => {
        setSelectedDateTime('')
        setFile(null)
        setSelectedAccount(null)
        onCancel()
    }
    return (
        <Dialog open={open} onClose={() => handleClose()} fullWidth maxWidth="lg">
            <Loading loading={isLoading} />
            <CustomAlert
                open={showAlert}
                message={alertOption.message}
                type={alertOption.type}
                onClose={() => setShowAlert(false)}
            />
            <DialogTitle>ชำระเงิน</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <Grid container spacing={2} sx={{ marginBottom: 1 }}>
                        <Grid item xs={12} md={6}>
                            <Card sx={{ minHeight: '100%' }}>
                                <Typography sx={{ justifyContent: 'center', display: 'flex' }} component='div' variant='h6'>
                                    รายละเอียด
                                </Typography>
                                <Typography sx={{ paddingLeft: 2 }} component='div'>
                                    เติมเกม : valorant
                                </Typography>
                                <Typography sx={{ paddingLeft: 2 }} component='div'>
                                    ชื่อในเกม : {details?.gameName || '-'}
                                </Typography>
                                <Typography sx={{ paddingLeft: 2 }} component='div'>
                                    จำนวนเงิน : {displayNumber(details?.amount)|| '-'} {details?.selectedCurrency}
                                </Typography>
                                <Typography sx={{ paddingLeft: 2 }} component='div'>
                                    Point ที่ได้ : {displayNumber(details?.point) || '-'} point
                                </Typography>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Card>
                                <CardMedia
                                    sx={{
                                        maxWidth: 250,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        margin: 'auto',
                                    }}
                                    component="img"
                                    alt={''}
                                    image={selectedAccount?.image}
                                />
                                <CardContent>
                                    <Typography component='div'>
                                        ธนาคาร : {selectedAccount?.title}
                                    </Typography>
                                    <Typography component='div'>
                                        ชื่อบัญชี : {selectedAccount?.name}
                                    </Typography>
                                    <Typography component='div'>
                                        เลขที่บัญชี : {selectedAccount?.no}
                                    </Typography>
                                    <Typography sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <Button onClick={() => changeSelected()}>เปลี่ยนบัญชี</Button>
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12}>
                            {/* <Button fullWidth variant='outlined'>แนบสลิป/อัพโหลดรูปภาพ</Button> */}
                            <input
                                accept="image/*"
                                style={{ opacity: 0, position: 'absolute', left: '-9999px' }}
                                id="file-upload"
                                type="file"
                                onClick={() => console.log('click upload')}
                                onChange={handleFileChange}
                            />
                            <label htmlFor="file-upload">
                                <Button
                                    variant="outlined"
                                    fullWidth
                                    component="span"
                                >
                                    {!file ? `แนบสลิป/อัพโหลดรูปภาพ` : `เปลี่ยนสลิป/รูปภาพ`}
                                </Button>
                            </label>
                        </Grid>
                        {file && <><Grid item xs={12} md={6} >
                            <div>
                                {`เลือกรูปภาพแล้ว : `} <span style={{ cursor: 'pointer', color: 'green', alignItems: 'center', display: 'flex' }} onClick={() => window.open(file.url, '_blank')}>{file.name}<OpenInNewIcon /> </span>
                            </div>
                            {/* <div>
                                <Button>บันทึก</Button>
                            </div> */}

                        </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    id="datetime-local"
                                    label="วันที่และเวลาในสลิป (ไม่จำเป็น)"
                                    type="datetime-local"
                                    defaultValue={selectedDateTime}
                                    onChange={handleChange}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        placeholder: 'Your Custom Placeholder',
                                    }}
                                />
                            </Grid>
                        </>
                        }
                    </Grid>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button sx={{ display: !file ? 'none' : '' }} onClick={() => confirmTransaction()} >บันทึก</Button>
                <Button onClick={() => handleClose()} color="secondary">
                    ย้อนกลับ
                </Button>
                {/* Add additional action buttons if needed */}
            </DialogActions>
        </Dialog>
    )
}

export default ModalPayment