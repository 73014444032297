import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

interface SuccessModalProps {
    open: boolean;
    onClose: () => void;
    message: string;
}

const SuccessModal: React.FC<SuccessModalProps> = ({ open, onClose, message }) => {
    return (
        <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
            <DialogTitle sx={{ display: 'flex', justifyContent: 'center' }}>
                <img src={'https://www.freeiconspng.com/thumbs/success-icon/success-icon-10.png'} alt="Success Icon" style={{ width: '200px' }} />
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <Typography color={'green'} variant="h6" align="center">
                        {message}
                    </Typography>
                    <Typography sx={{cursor:'pointer'}} variant="h6" component='div' onClick={()=>onClose()} align="center">
                        {`< ปิดหน้าต่าง >`}
                    </Typography>
                </DialogContentText>
            </DialogContent>
        </Dialog>
    );
};

export default SuccessModal;
