import React, { useEffect, useState } from 'react';
import {
    Button,
    Card,
    Container,
    Grid,
    MenuItem,
    Select,
    styled,
    TextField,
    Typography,
} from '@mui/material';
import PaymentCard from './PaymentCard';
import ModalPayment from './ModalPayment';
import Loading from './Loading';
import SuccessModal from './SuccessModal';
const Spacer = styled('div')({
    marginBottom: '16px',
});

const StyledCard = styled(Card)({
    padding: '16px',
});

const TopupComponent: React.FC = () => {
    const [gameName, setGameName] = useState<string>('');
    const [amount, setAmount] = useState<number | undefined>(undefined);
    const [selectedCurrency, setSelectedCurrency] = useState<string>('THB');
    const [selectedPayment, setSelectedPayment] = useState<'creditCard' | 'bankTransfer' | null>(null);
    const [isSubmit, setIsSubmit] = useState<boolean>(false)
    const [point, setPoint] = useState<number | null>(null)
    const [isModalPaymentOpen, setIsModalPaymentOpen] = useState<boolean>(false)
    const [details, setDetails] = useState<any | null>(null)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isSuccess, setIsSuccess] = useState<boolean>(false)

    const handlePaymentSelect = (paymentMethod: 'creditCard' | 'bankTransfer') => {
        setSelectedPayment(paymentMethod);
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        setIsSubmit(true)


        if (!gameName || !amount || !selectedPayment) {
            //invalid

        } else {
            calculationPoint()
            setIsLoading(true)
            setIsModalPaymentOpen(true)
            setIsLoading(false)
        }
    };
    useEffect(() => {
        if (amount) {
            calculationPoint()
        }
    }, [selectedCurrency, amount])


    const calculationPoint = () => {
        const thaibaht = selectedCurrency === 'RUB' ? ((amount || 1) * 0.39) : (selectedCurrency === 'USD' || selectedCurrency === 'USDT' ? ((amount || 1) * 35.3) : (amount || 1))
        const point = Math.round(thaibaht * 3.9)
        setPoint(point)
        setDetails({
            ...details,
            amount,
            selectedCurrency,
            gameName,
            point,
            selectedPayment
        })
    }
    const onSuccessPayment = () => {
        setIsModalPaymentOpen(false)
        setIsSuccess(true)
    }
    const onSuccessError = (message?: string) => {
        setIsModalPaymentOpen(false)
    }

    return (
        <>
            <Loading loading={isLoading} />
            <form onSubmit={handleSubmit}>
                <Grid sx={{ marginTop: 2 }} container spacing={2}>
                    <Grid item lg={4} xl={4} xs={12}>
                        <Container>
                            <Typography variant="h5">
                                <b>ขั้นตอนการเติม</b>
                            </Typography>
                            <p>1.กรอกชื่อในเกมและแท็ก</p>
                            <p>2.เลือกจำนวนพ้อยที่ต้องการ</p>
                            <p>3.ตรวจสอบรายละเอียดของรายการ</p>
                            <p>4.กดปุ่ม"ยืนยัน/ชำระเงิน"</p>
                            <p>5.ชำระเงินตามรายละเอียด</p>
                            <p>6.จะได้รับ Point ภายใน 15 นาที</p>
                            {/* ... Other content ... */}
                        </Container>
                    </Grid>
                    <Grid item lg={8} xl={8} xs={12}>
                        <StyledCard>
                            <Typography variant="h5">
                                <b>กรุณากรอกชื่อในเกมและแท็ก</b>
                            </Typography>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={12}>
                                    <TextField
                                        id='gameName'
                                        label="ชื่อในเกม#แท็ก"
                                        variant="outlined"
                                        placeholder='NAME#1234'
                                        fullWidth
                                        value={gameName}
                                        onChange={(e) => setGameName(e.target.value)}
                                        error={gameName === '' && isSubmit} // Set error to true when the field is empty
                                        helperText={(gameName === '' && isSubmit) ? 'กรุณากรอกชื่อในเกม' : ''}
                                        sx={{ marginTop: '24px' }}
                                    />
                                </Grid>
                                {/* <Grid item xs={12} sm={6}>
                                <TextField
                                    id='gameTag'
                                    label="แท็ก"
                                    variant="outlined"
                                    fullWidth
                                    value={gameTag || ''}
                                    onChange={(e) =>
                                        setGameTag(e.target.value)
                                    }
                                    error={gameTag === '' && isSubmit} // Set error to true when the field is empty
                                    helperText={(gameTag === '' && isSubmit) ? 'กรุณากรอกชื่อในเกม' : ''}
                                    sx={{ marginTop: '24px' }} 
                                />
                            </Grid> */}
                            </Grid>
                        </StyledCard>

                        <Spacer />

                        <StyledCard>
                            <Typography variant="h5">
                                <b>ระบุจำนวนเงิน</b>
                            </Typography>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        label="จำนวน"
                                        variant="outlined"
                                        fullWidth
                                        value={amount}
                                        type='number'
                                        onChange={(e) => setAmount(parseFloat(e.target.value) || undefined)}
                                        error={(!amount || amount == 0) && isSubmit} // Set error to true when the field is empty
                                        helperText={isSubmit ? (!amount ? 'กรุณาระบุจำนวนเงิน' : (amount <= 0 ? 'จำนวนเงินไม่ถูกต้อง' : '')) : ''}
                                        sx={{ marginTop: '24px' }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Select
                                        label="สกุลเงิน"
                                        variant="outlined"
                                        fullWidth
                                        value={selectedCurrency}
                                        onChange={(e) => setSelectedCurrency(e.target.value as string)}
                                        sx={{ marginTop: '24px' }}
                                    >
                                        <MenuItem value="THB">THB - Thai Baht</MenuItem>
                                        <MenuItem value="RUB">RUB - Russian Ruble</MenuItem>
                                        <MenuItem value="USD">USD - United States Dollar</MenuItem>
                                        <MenuItem value="USDT">USDT - Tether</MenuItem>
                                    </Select>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography component='div' variant='h6' sx={{ color: 'green' }}>
                                        Point ที่จะได้รับ : {point || '-'} {point ? 'points' : ''}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </StyledCard>

                        <Spacer />

                        <StyledCard>
                            <Typography variant="h5">
                                <b>เลือกช่องทางการชำระเงิน</b>
                            </Typography>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={12} sx={{ marginTop: 2 }}>
                                    <PaymentCard
                                        paymentMethod="creditCard"
                                        onSelect={handlePaymentSelect}
                                        selected={selectedPayment === 'creditCard'}
                                        disabled
                                    />

                                    <PaymentCard
                                        paymentMethod="bankTransfer"
                                        onSelect={handlePaymentSelect}
                                        selected={selectedPayment === 'bankTransfer'}
                                    />
                                    <Typography hidden={isSubmit ? selectedPayment != null : true} component='div' sx={{ paddingLeft: 2, fontSize: 12, color: 'red' }}>
                                        กรุณาเลือกช่องทางการชำระเงิน
                                    </Typography>
                                </Grid>
                            </Grid>
                        </StyledCard>

                        <Spacer />

                        <StyledCard>
                            <Typography variant="h5">
                                <b>สรุปการรายการ</b>
                            </Typography>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={12}>
                                    <Typography sx={{ paddingLeft: 2 }} component='div' variant='h6'>
                                        เติมเกม : valorant
                                    </Typography>
                                    <Typography sx={{ paddingLeft: 2 }} component='div' variant='h6'>
                                        ชื่อในเกม : {gameName || '-'}
                                    </Typography>
                                    <Typography sx={{ paddingLeft: 2 }} component='div' variant='h6'>
                                        จำนวนเงิน : {amount || '-'} {selectedCurrency}
                                    </Typography>
                                    <Typography sx={{ paddingLeft: 2 }} component='div' variant='h6'>
                                        Point ที่จะได้รับ : {point || '-'} point
                                    </Typography>
                                    <Typography sx={{ paddingLeft: 2 }} component='div' variant='h6'>
                                        วิธีการชำระเงิน : {selectedPayment ? (selectedPayment === 'creditCard' ? 'บัตรเครดิต/บัตรเดบิต' : 'โอนเงินผ่านธนาคาร') : '-'}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        type='submit'
                                    >
                                        ยืนยัน/ชำระเงิน
                                    </Button>
                                </Grid>
                            </Grid>
                        </StyledCard>
                    </Grid>
                </Grid>
            </form>
            <ModalPayment
                details={details}
                open={isModalPaymentOpen}
                onCancel={() => setIsModalPaymentOpen(false)}
                onSuccess={() => onSuccessPayment()}
                onError={() => onSuccessError()}
            />
            <SuccessModal
                open={isSuccess}
                onClose={()=>setIsSuccess(false)}
                message='ทำรายการสำเร็จ'
            />
        </>
    );
};

export default TopupComponent;
