// axiosService.js

import axios from 'axios';

class AxiosService {
  constructor() {
    this.instance = axios.create({
      baseURL: 'https://wetrust.concrete-today.com/api/', // replace with your API base URL
      timeout: 5000,
      headers: {
        'Content-Type': 'application/json',
      },
    });

    this.instance.interceptors.request.use(
      (config) => {
        // You can add custom logic before sending the request
        // For example, add an authentication token to the headers
        // config.headers.Authorization = `Bearer ${yourAuthToken}`;
        return config;
      },
      (error) => {
        // Handle request errors
        return Promise.reject(error);
      }
    );

    this.instance.interceptors.response.use(
      (response) => {
        // You can add custom logic to handle successful responses
        return Promise.resolve(response.data);
      },
      (error) => {
        // Handle response errors
        return Promise.reject(error);
      }
    );
  }

  // Set authentication token
  setAuthToken(token) {
    console.log('token',token);
    this.instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }

  // Clear authentication token
  clearAuthToken() {
    delete this.instance.defaults.headers.common['Authorization'];
  }

  // GET request
  get(url, params, config) {
    return this.instance.get(url, { params, ...config });
  }

  // POST request
  post(url, data, config) {
    return this.instance.post(url, data, config);
  }

  // PUT request
  put(url, data, config) {
    return this.instance.put(url, data, config);
  }

  // DELETE request
  delete(url, config) {
    return this.instance.delete(url, config);
  }
}

export default new AxiosService();
