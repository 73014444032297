import React, { ReactNode, useEffect, useState } from 'react';
import {
    Container,
    Typography,
    AppBar,
    Toolbar,
    Button,
    IconButton,
    Hidden,
    Drawer,
    List,
    ListItem,
    ListItemText,
    Alert,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { styled } from '@mui/system';
import ModalSignin from './ModalSignin';
import { alertService } from '../helpers/AlertService';
import { useDispatch, useSelector } from 'react-redux';
import AxiosService from '../helpers/AxiosService';
import { setUserInfo } from '../helpers/redux/actions';
import { useNavigate } from 'react-router-dom';

interface LayoutProps {
    children: ReactNode;
}

const StyledIconButton = styled(IconButton)(({ theme }) => ({
    color: theme.palette.primary.contrastText,
}));

const StyledContainer = styled(Container)(({ theme }) => ({
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
}));

const StyledAppBar = styled(AppBar)(({ theme }) => ({
    marginBottom: '10px',
    background: `linear-gradient(to bottom, ${theme.palette.primary.main}, rgba(30, 136, 229, 0))`,
    boxShadow: 'none',
}));


const Layout: React.FC<LayoutProps> = ({ children }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [openSignin, setOpenSignin] = useState(false);
    const [openSignout, setOpenSignout] = useState(false)
    const userInfo = useSelector((state: any) => state.userInfo);

    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
    };
    const handleClickSignin = () => {
        setOpenSignin(true)
    }
    const handleCloseSignin = () => {
        setOpenSignin(false)
    }
    const handleClickSignout = () => {
        setOpenSignout(true)
    }
    const handleCloseSignout = () => {
        setOpenSignout(false)
    }
    const confirmSignout = ()=>{
        console.log('confirmSignout');
        
        AxiosService.clearAuthToken()
        dispatch(setUserInfo({is_login : false}));
        setOpenSignout(false)
    }
    useEffect(() => {
        console.log('is show', alertService.isShow);

    }, [alertService.isShow])

    const handleChangeMenu = (path:string) => {
        // Navigate to another path without a page reload
        navigate(path);
      };
    return (
        <div>
            <Typography
                sx={{
                    position: 'fixed',
                    top: 0,
                    right: 0,
                    zIndex: 5000,
                    padding: 2,
                    display: !alertService.isShow ? 'none' : ''
                }}
            >
                <Alert onClose={() => { }}>{alertService.message}</Alert>
            </Typography>
            <StyledAppBar position="static">
                <Toolbar>
                    <Container sx={{ justifyContent: 'space-between', display: 'flex', alignItems: 'center' }}>
                        <Typography style={{ cursor: 'pointer' }} onClick={() => { }} variant="h5">
                            <img src="/logo2.png" alt="Logo" style={{ height: '65px', marginRight: '10px' }} />
                        </Typography>
                        <div>
                            <Hidden mdUp>
                                {/* Hamburger icon visible on smaller screens */}
                                <StyledIconButton edge="end" color="inherit" aria-label="menu" onClick={handleDrawerToggle}>
                                    <MenuIcon sx={{ fontSize: 50 }} />
                                </StyledIconButton>
                            </Hidden>
                            <Hidden mdDown>
                                {/* Regular buttons visible on larger screens */}
                                <Button color="inherit" onClick={()=>handleChangeMenu("/")} sx={{ fontSize: '1.2rem' }}>
                                    หน้าหลัก
                                </Button>
                                <Button color="inherit" onClick={()=>handleChangeMenu("/")} sx={{ fontSize: '1.2rem' }}>
                                    เติมเกม
                                </Button>
                                <Button disabled color="inherit" onClick={()=>handleChangeMenu("/manual")} sx={{ fontSize: '1.2rem' }}>
                                    วิธีใช้
                                </Button>
                                <Button disabled color="inherit" onClick={()=>handleChangeMenu("/contact")} sx={{ fontSize: '1.2rem' }}>
                                    ติดต่อเรา
                                </Button>
                                <Button color="inherit" onClick={() => !userInfo.is_login ? handleClickSignin() : handleClickSignout()} sx={{ fontSize: '1.2rem' }}>
                                    {userInfo.is_login ? `สมาชิก(${userInfo.name || userInfo.username})` : 'เข้าสู่ระบบ'}
                                </Button>
                                <Button color="inherit" onClick={() => handleChangeMenu("/admin")} sx={{ fontSize: '1.2rem', display: userInfo.is_login ? "" : "none" }}>
                                    รายการ
                                </Button>
                            </Hidden>
                        </div>
                    </Container>

                </Toolbar>
            </StyledAppBar>

            {/* Responsive Sidebar (Drawer) */}
            <Hidden mdUp>
                <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerToggle}>
                    <List>
                        <ListItem button key="home">
                            <ListItemText primary="Home" />
                        </ListItem>
                        <ListItem button key="topup">
                            <ListItemText primary="Top Up" />
                        </ListItem>
                        <ListItem button key="manual">
                            <ListItemText primary="Manual" />
                        </ListItem>
                        <ListItem button key="contact">
                            <ListItemText primary="Contact" />
                        </ListItem>
                        <ListItem button key="contact1">
                            <ListItemText primary="Contact1" />
                        </ListItem>
                    </List>
                </Drawer>
            </Hidden>

            <StyledContainer>{children}</StyledContainer>
            <ModalSignin open={openSignin} onClose={() => handleCloseSignin()} />
            <Dialog open={openSignout} onClose={()=>handleCloseSignout()}>
                <DialogTitle>ต้องการออกจากระบบ ?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        คุณต้องการออกจากระบบ ใช่หรือไม่ ?
                    </DialogContentText>
                    {/* <TextField
                        autoFocus
                        margin="dense"
                        id="username"
                        label="Username"
                        type="text"
                        fullWidth
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        id="password"
                        label="Password"
                        type="password"
                        fullWidth
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    /> */}
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>handleCloseSignout()} color='secondary'>
                        ย้อนกลับ
                    </Button>
                    <Button onClick={()=>confirmSignout()} variant='outlined' color="primary">
                        ออกจากระบบ
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default Layout;
