// reducers/userInfoReducer.ts

interface UserInfo {
    username: string;
    id: number | null;
    image: string;
    is_login:boolean;
    // Add other fields as needed
  }
  
  interface SetUserInfoAction {
    type: 'SET_USER_INFO';
    payload: UserInfo;
  }
  
  interface SetUsernameAction {
    type: 'SET_USERNAME';
    payload: string;
  }
  
  interface SetIdAction {
    type: 'SET_ID';
    payload: number | null;
  }
  
  interface SetImageAction {
    type: 'SET_IMAGE';
    payload: string;
  }
  
  type UserInfoAction = SetUserInfoAction | SetUsernameAction | SetIdAction | SetImageAction;
  
  const initialState: UserInfo = {
    username: '',
    id: null,
    image: '',
    is_login:false
    // Initialize other fields
  };
  
  const userInfoReducer = (state: UserInfo = initialState, action: UserInfoAction): UserInfo => {
    switch (action.type) {
      case 'SET_USER_INFO':
        return { ...state, ...action.payload };
      case 'SET_USERNAME':
        return { ...state, username: action.payload };
      case 'SET_ID':
        return { ...state, id: action.payload };
      case 'SET_IMAGE':
        return { ...state, image: action.payload };
      // Add other actions as needed
      default:
        return state;
    }
  };
  
  export default userInfoReducer;
  