import React, { useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
} from '@mui/material';
import Loading from './Loading';
import { Login } from '../helpers/ApiService';
import CustomAlert from './CustomAlert';
import { useDispatch, useSelector } from 'react-redux';
import { setUserInfo } from '../helpers/redux/actions';
import AxiosService from '../helpers/AxiosService';
import { setLocalStorage } from '../helpers/Tools';

interface LoginModalProps {
    open: boolean;
    onClose: () => void;
    onSuccess?: () => void;
}

const ModalSignin: React.FC<LoginModalProps> = ({ open, onClose, onSuccess }) => {
    const dispatch = useDispatch();
    const userInfo = useSelector((state: any) => state.userInfo);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [showAlert, setShowAlert] = useState<boolean>(false)
    const [alertOption, setAlertOption] = useState({ message: 'success', type: 'success' })

    const handleLogin = () => {
        // Implement your login logic here
        console.log(`Logging in with username: ${username}, password: ${password}`);
        // Close the modal
        setIsLoading(true)
        // onClose();
        const payload: any = {
            username: username,
            password: password
        }
        Login(payload).then((response: any) => {
            notiSuccess('เข้าสู่ระบบสำเร็จ')
            console.log('response', response);
            AxiosService.setAuthToken(response.token)
            dispatch(setUserInfo({ ...response.user, is_login: true }));
            setLocalStorage('token',response.token)
            // AxiosService.setAuthToken(response.)
            setIsLoading(false)
            onClose()
        }).catch((error: any) => {
            notiError('มีบางอย่างผิดพลาด')
            dispatch(setUserInfo(null));
            setIsLoading(false)
        })
    };
    const notiSuccess = (message?: string) => {
        setAlertOption({ message: message || 'success', type: 'success' })
        setShowAlert(true)
    }
    const notiError = (message?: string) => {
        setAlertOption({ message: message || 'error', type: 'error' })
        setShowAlert(true)
    }
    useEffect(() => {
        console.log('userInfo', userInfo);

    }, [userInfo])
    useEffect(() => {
        if (!open) {
            setUsername("")
            setPassword("")
        }
    }, [open])


    return (
        <>
            <CustomAlert
                open={showAlert}
                message={alertOption.message}
                type={alertOption.type}
                onClose={() => setShowAlert(false)}
            />
            <Dialog open={open} onClose={onClose}>
                <Loading loading={isLoading} />
                <DialogTitle>เข้าสู่ระบบสมาชิก</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please enter your username and password to log in.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="username"
                        label="Username"
                        type="text"
                        fullWidth
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        id="password"
                        label="Password"
                        type="password"
                        fullWidth
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color='secondary'>
                        ย้อนกลับ
                    </Button>
                    <Button onClick={handleLogin} variant='outlined' color="primary">
                        เข้าสู่ระบบ
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ModalSignin;
