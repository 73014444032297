import React, { useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import Routes from './Routes';
import { useDispatch, useSelector } from 'react-redux';
import { getLocalStorage, getTimeLeftFromJwt, removeLocalStorage } from './helpers/Tools';
import { GetInfo } from './helpers/ApiService';
import AxiosService from './helpers/AxiosService';
import { setUserInfo } from './helpers/redux/actions';

const theme = createTheme({
  typography: {
    fontFamily: 'Mali, sans-serif',
  },
  palette: {
    primary: {
      main: '#F63F1E'
    },
    secondary: {
      main: '#666666'
    }
  }
})
function App() {
  const dispatch = useDispatch();
  const userInfo = useSelector((state: any) => state.userInfo);
  useEffect(() => {
    console.log('calling App.tsx');
    const token = getLocalStorage('token')
    if (token) {
      AxiosService.setAuthToken(token)
    }else{
      AxiosService.setAuthToken()
    }
    if (token && !userInfo.is_login) {
      const timeLeft = getTimeLeftFromJwt(token)
      console.log('timeLeft',timeLeft);
      
      if (timeLeft && timeLeft > 0) {
        console.log('get info');

        AxiosService.setAuthToken(token)
        GetInfo().then((response: any) => {
          console.log('info', response);
          dispatch(setUserInfo({ ...response, is_login: true }));
        })
      }else{
        removeLocalStorage('token')
      }

    }
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes />
    </ThemeProvider>
  );
}

export default App;
