// reducers/index.ts

import { combineReducers } from 'redux';
import userInfoReducer from './userInfoReducer'; // Update the path as needed

const rootReducer = combineReducers({
  userInfo: userInfoReducer,
  // Add other reducers as needed
});

export default rootReducer;
