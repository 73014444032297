import { Alert, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'

interface AlertProps {
    open: boolean,
    onClose?: () => any,
    message?: string,
    type?: string,
    duration?: number
}
const CustomAlert: React.FC<AlertProps> = ({ open, onClose, message, type, duration = 3000 }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false)
    useEffect(() => {
        if (open) {
            setIsOpen(true)
            setTimeout(() => {
                setIsOpen(false)
                handleClose()
            }, duration || 3000);
        }
    }, [open])
    const handleClose = () => {
        if (onClose) {
            onClose()
        }
    }

    return (
        <Typography
            sx={{
                position: 'fixed',
                top: 0,
                right: 0,
                zIndex: 5000,
                padding: 2,
                display: !isOpen ? 'none' : ''
            }}
        >
            <Alert security={type || 'success'} onClose={() => { handleClose() }}>{message || 'success'}</Alert>
        </Typography>
    )
}

export default CustomAlert