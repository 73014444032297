import CryptoJS from "crypto-js";

// Secret key for encryption (replace with your own secret key)
const SECRET_KEY = "your-secret-key";

export const setLocalStorage = (name: string, value: any) => {
  try {
    const valueString = JSON.stringify(value);
    const encryptedValue = CryptoJS.AES.encrypt(
      valueString,
      SECRET_KEY
    ).toString();
    window.localStorage.setItem(name, encryptedValue);
  } catch (error) {
    console.error(
      `Error encrypting and storing localStorage value for key '${name}':`,
      error
    );
  }
};

export const getLocalStorage = (name: string): any | null => {
  try {
    const storedValue = window.localStorage.getItem(name);
    if (storedValue) {
      const bytes = CryptoJS.AES.decrypt(storedValue, SECRET_KEY);
      const decryptedValue = bytes.toString(CryptoJS.enc.Utf8);
      return decryptedValue ? JSON.parse(decryptedValue) : null;
    }
    return null;
  } catch (error) {
    console.error(
      `Error decrypting localStorage value for key '${name}':`,
      error
    );
    return null;
  }
};
export const removeLocalStorage = (name: string) => {
  window.localStorage.removeItem(name);
};
interface DecodedToken {
  exp: number; // Expiration time in seconds
}

export const getTimeLeftFromJwt = (jwt: string): number | null => {
  try {
    // Extract the payload from the JWT
    const [, payloadBase64] = jwt.split(".");
    const decodedPayload = JSON.parse(atob(payloadBase64)) as DecodedToken;

    // Check if the 'exp' property exists in the decoded payload
    if (decodedPayload && decodedPayload.exp) {
      // Calculate the time left in seconds
      const currentTimeInSeconds = Math.floor(Date.now() / 1000);
      const timeLeftInSeconds = decodedPayload.exp - currentTimeInSeconds;

      return timeLeftInSeconds > 0 ? timeLeftInSeconds : 0;
    }

    return null; // 'exp' property not found in the payload
  } catch (error) {
    console.error("Error decoding JWT:", error);
    return null; // Error decoding JWT
  }
};

export const displayNumber = (number: number) => {
  if (isNaN(number)) {
    console.error("Invalid input. Please provide a valid number.");
    return "Invalid Number";
  }

  return number.toLocaleString("en-US", {
    style: "decimal",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};
