// actions/userInfoActions.ts

interface UserInfo {
    username?: string;
    id?: number | null;
    image?: string;
    // Add other fields as needed
  }
  
  export const setUserInfo = (userInfo: any | null) => ({
    type: 'SET_USER_INFO',
    payload: userInfo,
  });
  
  export const setUsername = (username: string) => ({
    type: 'SET_USERNAME',
    payload: username,
  });
  
  export const setId = (id: number | null) => ({
    type: 'SET_ID',
    payload: id,
  });
  
  export const setImage = (image: string) => ({
    type: 'SET_IMAGE',
    payload: image,
  });
  
  // Add other actions as needed
  