import React, { useState } from 'react'
import { Avatar, Card, CardContent, CardMedia, Typography } from '@mui/material';
interface CardImageProps {
    name: string,
    banner: string,
    id: number,
    onClick?: () => void,
    image?: string,
    disabled?: boolean
}
const CardImage: React.FC<CardImageProps> = ({ name, banner, id, onClick, image = '', disabled = false }) => {
    const [isHovered, setIsHovered] = useState<boolean>(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };
    return (
        <Typography onClick={() => onClick ? onClick() : ''}>
            <Card
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}

                style={{
                    position: 'relative',
                    transition: 'transform 0.3s', // Add a transition for a smooth scaling effect
                    transform: isHovered ? 'scale(1.03)' : 'scale(1)', // Scale up on hover
                    cursor: 'pointer'
                }}
            >
                <CardMedia
                    component="img"
                    alt={name}
                    height="140"
                    image={image}
                />
                {banner && (
                    <Typography
                        variant="subtitle1"
                        component="div"
                        style={{
                            fontWeight: 'bold',
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            width: 'fit-content',
                            background: 'rgba(255, 0, 0, 0.5)', // Adjust the background color and opacity
                            color: '#fff', // Adjust the text color
                            padding: '8px',
                            textAlign: 'center', // Adjust the text alignment
                        }}
                    >
                        {banner}
                    </Typography>
                )}

                {/* Card Content */}
                <CardContent>
                    <Typography style={{ textAlign: 'center', fontWeight: 'bold' }} variant="h6" component="div">
                        {name}
                    </Typography>
                    <Typography style={{ textAlign: 'center', fontSize: 14, color: disabled ? 'red' : 'green' }} component="div">
                        {disabled ? 'เร็วๆนี้' : 'พร้อมให้บริการ'}
                    </Typography>
                </CardContent>
            </Card>
        </Typography>
    );
};


export default CardImage