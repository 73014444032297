// AlertService.js

class AlertService {
    _isShow = false;
    _message = "success";
    _type = "success";
  
    get isShow() {
      return this._isShow;
    }
    get message() {
      return this._message;
    }
    get type() {
      return this._type;
    }
  
    show(message, type) {
      console.log("call show");
      this._message = message || "success";
      this._type = type || "success";
      this._isShow = true;
    }
  
    hide() {
      this._isShow = false;
    }
  }
  
  const alertService = new AlertService();
  export { alertService };
  