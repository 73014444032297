// src/Routes.tsx
import React from 'react';
import { BrowserRouter as Router, Route, Routes as ReactRoutes } from 'react-router-dom';
import Layout from './components/Layout';
import Home from './pages/Home';
import Admin from './pages/Admin';

const AppRoutes: React.FC = () => {
    return (
        <ReactRoutes>
            <Route path="/" element={<Layout><Home /></Layout>} />
            <Route path="/admin" element={<Admin />} />
        </ReactRoutes>
    );
};
const AdminRoutes: React.FC = () => {
    return (
        <ReactRoutes>
            {/* <Route path="/admin" element={<Admin />} /> */}
        </ReactRoutes>
    );
};

const Routes: React.FC = () => {
    return (
        <Router>
            {/* <Layout> */}
            <AppRoutes />
            {/* </Layout> */}
            {/* <AdminRoutes /> */}
        </Router>
    );
};

export default Routes;
