import React, { useEffect, useState } from 'react'
import Loading from '../components/Loading'
import { Grid, Typography } from '@mui/material'
import CardImage from '../components/CardImage'
import TopupComponent from '../components/TopupComponent'

const Home = () => {
    const [loading, setLoading] = useState<boolean>(true)
    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 1000);
    }, [])

    return (
        <div style={{ position: 'relative'}}>
            <Loading loading={loading} />
            <div>
                <div>
                    <img
                        src='https://mir-s3-cdn-cf.behance.net/project_modules/fs/a7e41c107454175.612b8221217c3.jpg'
                        alt="Banner description"
                        style={{ width: '100%', marginBottom: '16px' }}
                    />
                </div>
                <div>
                    <Typography component="h2" variant='h5' style={{ textAlign: 'center' }}>ยอดนิยม</Typography>
                    <Grid container spacing={2} justifyContent="center" alignItems="top" style={{ marginTop: 1 }}>
                        <Grid item xs={12} md={6} lg={3}>
                            <CardImage id={1} name='Valorant' banner='HOT' image='https://images.contentstack.io/v3/assets/bltb6530b271fddd0b1/bltc04e4637524166dc/621853fd6be1e66143a66db2/022822_TakeoverCap_Banner.jpg?auto=webp&disable=upscale&height=549' />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <CardImage id={2} disabled name='Ragnarok (RO)' banner='SOON' image='https://s.isanook.com/ga/0/ud/210/1053585/ro-gravity-game-tech.jpg?ip/crop/w670h402/q80/jpg' />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <CardImage id={3} disabled name='Genshin impact' banner='SOON' image='https://assetsio.reedpopcdn.com/Genshin-Impact-beginner%E2%80%99s-guide-for-2023%2C-tips-and-tricks-cover.jpg?width=1200&height=1200&fit=bounds&quality=70&format=jpg&auto=webp' />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <CardImage id={4} disabled name='RoV' banner='SOON' image='https://bangkokesports.com/wp-content/uploads/2019/02/6196806354556f9baeeae2f3c4d96d0b.jpg' />
                        </Grid>
                    </Grid>
                </div>
                <TopupComponent/>
            </div>
        </div>
    )
}

export default Home